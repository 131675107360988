import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { UserInformation } from '@shared/model/credentials';
import { IResponse } from '@shared/model/IResponse';
import {
  ConfirmUserEmailDto,
  IForgotPasswordRequest,
  IResetPasswordRequest,
  LoginResponse,
  LoginUserDto,
  RegisterUserDto,
} from '@shared/model/user';
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /**
   * The key used to store the user's credentials in the local storage.
   */
  private readonly credentialsKey = environment.delphiusCredentials;
  referralCode = '';

  /**
   * Initializes the `AuthenticationService` instance by injecting the `HttpClient` and `ErrorService` dependencies.
   * It also retrieves the user's credentials from the local storage, if available.
   * @param httpClient - The `HttpClient` instance used to make HTTP requests.
   */
  constructor(private readonly httpClient: HttpClient) {}

  get isTokenExpired(): string | null {
    const token = localStorage.getItem(this.credentialsKey);
    if (token) {
      const decodedToken = jwt_decode<{ exp: number }>(token);
      const isExpired = decodedToken.exp < Date.now() / 1000;
      if (isExpired) {
        localStorage.removeItem(this.credentialsKey);
        return null;
      }
    }
    return token;
  }

  /**
   * Checks if the user has a valid token.
   * @returns A boolean value indicating whether the user has a valid token or not.
   */
  canActivate(): Observable<boolean> {
    return of(!!this.isTokenExpired);
  }

  /**
   * Retrieves the user's information from the decoded JWT token.
   * @returns The user's information as an instance of the `UserInformation` interface, or `null` if no credentials are available.
   */
  public userInfo(): UserInformation | null {
    if (!this.isTokenExpired) {
      return null;
    }

    return this.decodeJWT(this.isTokenExpired);
  }

  /**
   * Retrieves the role of the user from the decoded JWT token.
   * @returns The role of the user, or `null` if the token is not available.
   */
  get userRole(): string[] | undefined {
    if (!this.isTokenExpired) {
      return [];
    }
    const decodedToken = this.decodeJWT(this.isTokenExpired);
    return decodedToken?.Role;
  }

  public addToken(value: string | undefined): void {
    if (value !== undefined) localStorage.setItem(this.credentialsKey, value);
  }

  public deleteToken(): void {
    localStorage.removeItem(this.credentialsKey);
  }

  /**
   * Decodes a JWT token using the jwt_decode library.
   * If the decoding is successful, the decoded token is returned.
   * If an error occurs during decoding, an error message is logged to the console and null is returned.
   * @param token - The JWT token to be decoded.
   * @returns The decoded JWT token, or null if an error occurred during decoding.
   */
  public decodeJWT(token: string): UserInformation | null {
    try {
      const decodedToken = jwt_decode<UserInformation>(token);
      return decodedToken;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  /**
   * Registers a new user by making a POST request to the authentication API endpoint.
   * @param registerUserDto - An object containing the user's registration details, including the client ID, email, password, first name, and last name.
   * @returns An observable that emits the registration response from the API.
   */
  registerUser(registerUserDto: RegisterUserDto): Observable<IResponse> {
    const url = `${environment.api}/Authentication/register-merchant`;
    if (!registerUserDto.referralCode) {
      registerUserDto.referralCode = environment.clientId;
    }
    return this.httpClient.post<IResponse>(url, registerUserDto);
  }

  /**
   * Logs in a user by sending a POST request to the authentication API endpoint.
   * @param loginUserDto An object containing the user's login details, including the username and password.
   * @returns An observable that emits the login response from the API.
   */
  loginUser(loginUserDto: LoginUserDto): Observable<LoginResponse> {
    const url = `${environment.api}/Authentication/login`;
    return this.httpClient.post<LoginResponse>(url, loginUserDto);
  }

  public confirmEmail(confirmUserDto: ConfirmUserEmailDto): Observable<IResponse> {
    const url = `${environment.api}/Authentication/confirm-mail`;
    return this.httpClient.post<IResponse>(url, confirmUserDto);
  }

  forgotPassword(forgotPasswordDto: IForgotPasswordRequest): Observable<IResponse> {
    const url = `${environment.api}/Authentication/forgot-password`;
    return this.httpClient.post<IResponse>(url, forgotPasswordDto);
  }

  resetPassword(resetPasswordDto: IResetPasswordRequest): Observable<IResponse> {
    const url = `${environment.api}/Authentication/reset-password`;
    return this.httpClient.post<IResponse>(url, resetPasswordDto);
  }
}
