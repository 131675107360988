export const constants = Object.freeze({
  errorMessage: 'There are some unknown Error. Please try again after some time.',
  InvalidEmailEN: '10011',
  ClientNotFoundEN: '10004',
  UserExistsEN: '10003',
  UserExistsWithCustomerRoleEN: '10042',
  RoleNotFoundEN: '10040',
  ApplicationConfigNotFoundEN: '14002',
  UserSuccessEN: '10000',
  EmailVerificationUrlNotSentEN: '10039',
  SystemError: '10040',
  LOGIN_ROUTE: '/auth/sign-in',
  CLIENT_NOT_FOUND_FCI: '10118',
  PasswordRegex: '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{12,24}',
  CorporateAddressTab: 1,
  MailingAddressTab: 2,
  LocationAddressTab: 3,
  MobileLength: 10,
  mobileNumberRegex: /^\(\d{3}\)-\d{3}-\d{4}$/,
  MerchantUnderReviewEN: '10202',
  mnWithSpace: 13,
  mnWithOutSpace: 12,
  UnAuthorizedErrorMessage:
    "Looks like you don't have permission to access this page. If you believe you should have access, please contact your administrator",
  SignUpPasswordRegex:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#^&()_+=~[\]{\\|<>.,/])[A-Za-z\d@$!%*#{}^&()_+=~[\]{\\|<>.,/]{12,24}$/,
});
