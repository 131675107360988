import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has('X-Skip-Token')) {
      const headers = request.headers.delete('X-Skip-Token');
      return next.handle(request.clone({ headers }));
    }
    const credentials = this.authenticationService.isTokenExpired;
    const currentUrl = this.router.url;
    const isExemptedUrl = currentUrl.includes('/auth') || currentUrl.includes('/additional-documents?track_no');
    if (isExemptedUrl) {
      return next.handle(request.clone());
    } else if (!credentials) {
      const encodedReturnUrl = encodeURIComponent(currentUrl);
      this.router.navigate(['/auth/sign-in'], { queryParams: { returnUrl: encodedReturnUrl } });
      return new Observable<HttpEvent<unknown>>();
    }

    return next.handle(this.addTokenToRequest(request, credentials));
  }

  /**
   * Adds the authentication token to the request headers.
   * @param request - The original request.
   * @param credentials - The user's credentials.
   * @returns A new request with the updated headers.
   */
  private addTokenToRequest(request: HttpRequest<unknown>, credentials: string): HttpRequest<unknown> {
    const authReq = request.clone({
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        Authorization: `Bearer ${credentials}`,
        'Permissions-Policy':
          'camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*',
        'Strict-Transport-Security': 'max-age=31536000; includeSubdomains',
        'X-Frame-Options': 'SAMEORIGIN',
        'X-Content-Type-Options': 'nosniff',
        'X-Xss-Protection': '1; mode=block',
        'Content-Security-Policy':
          "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;",
      }),
    });

    // Clone the request with the updated headers.
    return request.clone(authReq);
  }
}

export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
};
