import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-save-quite',
  templateUrl: './save-quite.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class SaveQuiteComponent {
  @Input() isLoading = false;
}
