<div class="fixed top-0 right-0 left-0 z-50" [ngClass]="[className ? className : '']">
  <div class="mx-20">
    <header class="toolbar flex items-center justify-between">
      <div class="sm:hidden" (click)="handleOnClick()" *ngIf="!isAuth">
        <img class="md:h-6 md:w-6 h-4 w-4" alt="menu" src="assets/icons/menu-mobile.svg" />
      </div>
      <div class="md:max-w-sm md:ml-10">
        <a [routerLink]="userInfo !== null ? 'company-information' : 'home'">
          <img class="w-16 sm:w-[80px]" alt="ionia Logo" [src]="logoURL" />
        </a>
      </div>
      <!-- single slot transclusion here -->
      <ng-content></ng-content>
    </header>
  </div>
</div>
