import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UserInformation } from '@shared/model/credentials';
import { AlmostDoneService } from '@shared/services/almost-done.service';
import { AuthenticationService } from '@shared/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [RouterModule, NgClass, NgIf],
})
export class HeaderComponent implements OnInit {
  isAuth = false;
  @Input() logoURL? = 'assets/logo/logo_idp.png';
  @Input() className? = '';

  userInfo: UserInformation | null = null;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly almostDoneService: AlmostDoneService,
    private router: Router
  ) {
    if (this.router.url.startsWith('/auth')) {
      this.isAuth = true;
    }
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  handleOnClick(): void {
    this.almostDoneService.toggleSidebar();
  }

  private getUserInfo(): void {
    this.userInfo = this.authService.userInfo();
  }
}
