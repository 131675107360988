import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { HeaderComponent } from '@shared/components/header/header.component';
import { FormValidationsMsg } from '@shared/form.validation.msg';
import { IResponse } from '@shared/model/IResponse';
import { IContactUsRequest } from '@shared/model/profile';
import { ProfileService } from '@shared/services/profile.service';
import { VerifyService } from '@shared/services/verify.service';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [RouterLink, CommonModule, HeaderComponent, RouterModule, ReactiveFormsModule, DialogModule],
})
export class HomeComponent extends FormValidationsMsg implements OnInit {
  referralCode: string | undefined;
  contactForm!: FormGroup;
  isContactVisible = false;
  isVideo = false;
  date = new Date();

  modalTitle = 'Contact Us';
  modalWidth = '400px';

  private sub$ = new Subscription();

  constructor(
    private readonly profileService: ProfileService,
    private readonly fb: FormBuilder,
    override messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly verifyService: VerifyService
  ) {
    super(messageService);
    this.route.queryParams.subscribe((params) => {
      this.referralCode = params['referralCode'];
      if (this.referralCode) {
        this.sub$.add(
          this.verifyService.verifyReferralCode(this.referralCode).subscribe({
            error: (error) => {
              this.router.navigateByUrl('/restricted');
            },
          })
        );
      }
    });
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  openVideo() {
    this.modalTitle = 'Video';
    this.modalWidth = '600px';
    this.isContactVisible = true;
    this.isVideo = true;
  }

  handleToApply() {
    this.router.navigate(['/auth/sign-up']);
  }

  handleOnApply(): void {
    const path = '/auth/sign-up' + (this.referralCode ? `?referralCode=${this.referralCode}` : '');
    this.router.navigateByUrl(path);
  }

  get f() {
    return this.contactForm.controls;
  }

  submit(): void {
    if (this.contactForm.invalid) {
      this.markAllFormControlsAsTouched();
      return;
    }

    const request: IContactUsRequest = this.contactForm.value;
    request.isFromHome = true;

    this.sub$.add(
      this.profileService.contactUsEmail(request).subscribe((response: IResponse) => {
        if (response.statusCode === '10000') {
          this.showToastMessage(response.message, 'success');
          this.isContactVisible = false;
          this.contactForm.reset();
        }
      })
    );
  }

  cancel(): void {
    this.isContactVisible = false;
    this.contactForm.reset();
  }

  private initializeForm() {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
    });
  }

  private markAllFormControlsAsTouched(): void {
    Object.values(this.contactForm.controls).forEach((control) => {
      control.markAllAsTouched();
    });
  }
}
