import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IResponse, IResponseType } from '@shared/model/IResponse';
import { ChangePasswordRequest, IContactUsRequest, Profile, UpdateEmail } from '@shared/model/profile';
import { LoginResponse } from '@shared/model/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private readonly httpClient: HttpClient) {}

  getprofiledetails(): Observable<IResponseType<Profile>> {
    const url = `${environment.api}/User/get-merchant-profile-details`;
    return this.httpClient.get<IResponseType<Profile>>(url);
  }

  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<LoginResponse> {
    const url = `${environment.api}/User/change-password`;
    return this.httpClient.post<LoginResponse>(url, changePasswordRequest);
  }

  updateProfile(updateEmail: UpdateEmail): Observable<IResponse> {
    const url = `${environment.api}/User/update-merchant-email-address`;
    return this.httpClient.post<IResponse>(url, updateEmail);
  }

  contactUsEmail(req: IContactUsRequest): Observable<IResponse> {
    const url = `${environment.api}/User/contact-us-email`;
    return this.httpClient.post<IResponse>(url, req);
  }

  underWritingEmail(req: IContactUsRequest): Observable<IResponse> {
    const url = `${environment.api}/User/UnderWriting-email`;
    return this.httpClient.post<IResponse>(url, req);
  }
}
