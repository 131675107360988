import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { ErrorMessageComponent } from '@shared/components/error-message/error-message.component';
import { FileListComponent } from '@shared/components/file-list/file-list.component';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { IoniaButtonComponent } from '@shared/components/ionia-button/ionia-button.component';
import { PageLayoutComponent } from '@shared/components/page-layout/page-layout.component';
import { FormValidationsMsg } from '@shared/form.validation.msg';
import { FileFormatType } from '@shared/model/ICompanyDocument';
import { UnderWritingService } from '@shared/services/under-writing.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-underwriting',
  templateUrl: './underwriting.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PageLayoutComponent,
    IoniaButtonComponent,
    FileUploadComponent,
    FileListComponent,
    ReactiveFormsModule,
    ErrorMessageComponent,
    HeaderComponent,
  ],
})
export class UnderwritingComponent extends FormValidationsMsg implements OnInit {
  isLoading = signal(false);
  token = '';
  maxUploadDocs = 10;
  underWritingDocs: FileFormatType[] = [];
  underWritingForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly underWritingService: UnderWritingService,
    override messageService: MessageService
  ) {
    super(messageService);
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = this.extractAfter37Chars(params['track_no']);
    });
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  /**
   * Submits the underwriting form data to create a new underwriting entry.
   * Sets loading state to true during submission.
   * Navigates to the home page upon successful submission.
   * Displays an error message and logs the error if submission fails.
   */
  onSubmit(): void {
    this.isLoading.set(true);
    this.underWritingService.createUnderWriting(this.underWritingForm.value, this.token).subscribe({
      next: () => {
        this.isLoading.set(false);
        this.resetFormAndDocs();
        this.router.navigate(['/home']);
      },
      error: (error) => {
        this.isLoading.set(false);
        this.showToastMessage('Please connect with Support team!', 'error');
        console.error('Error submitting underwriting form:', error);
      },
    });
  }

  /**
   * Handles the file upload process by checking for duplicates and adding new documents to the underwriting list.
   *
   * @param documents An array of FileFormatType objects representing the documents to be uploaded.
   * @returns void
   */
  handleFileUpload(documents: FileFormatType[]): void {
    const isDuplicate = this.underWritingDocs.some((file) => file.name === documents[0].name);
    if (!isDuplicate) {
      this.addNewDocument(documents[0]);
    }
  }

  extractAfter37Chars = (trackNo: string): string => {
    // Extract substring after 33 characters
    return trackNo.slice(37);
  };

  isValidDendButton(): boolean {
    return this.underWritingForm.invalid || this.underWritingDocs.length === 0;
  }

  handleOnDeleted(item: FileFormatType): void {
    const index = this.underWritingDocs.findIndex((doc) => doc.id === item.id);
    if (index !== -1) {
      this.underWritingDocs.splice(index, 1);
      this.getUnderwritingFiles.removeAt(index);
    }
  }

  get getUnderwritingFiles(): FormArray {
    return this.underWritingForm.get('additionalDocuments') as FormArray;
  }

  get f() {
    return this.underWritingForm.controls;
  }

  private resetFormAndDocs(): void {
    this.underWritingDocs = [];
    this.underWritingForm.reset();
  }

  /**
   * Adds a new document to the underwriting list and creates a corresponding form group for the document.
   *
   * @param document The FileFormatType object representing the document to be added.
   * @returns void
   */
  private addNewDocument(document: FileFormatType): void {
    this.underWritingDocs.push(document);
    this.getUnderwritingFiles.push(
      this.fb.group({
        id: document.id,
        name: document.name,
      })
    );
  }

  private initializeForm() {
    this.underWritingForm = this.fb.group({
      additionalDocuments: this.fb.array([]),
      feedback: ['', [Validators.required]],
    });
  }
}
