<div class="flex items-center justify-center border h-full shadow-2xl">
  <div class="border w-5/12 p-4 relative">
    <div role="button" class="absolute right-2 top-2">
      <i class="pi pi-times text-base"></i>
    </div>
    <div class="flex items-center justify-center flex-col mt-20">
      <div class="flex flex-col gap-x-6 items-center">
        <h3 class="text-xl font-bold">Check your phone</h3>
        <p class="text-sm font-semibold">We need to verify your mobile carrier information.</p>
      </div>
      <div class="h-60 w-60 rounded-full bg-slate-100 mt-4 flex items-center justify-center">
        <img src="assets/icons/phone_image.svg" alt="phone_image" class="h-52" />
      </div>

      <div class="mt-10 flex items-center justify-center flex-col">
        <h3 class="text-sm text-center">
          A text message with a link was just sent to the phone
          <br />
          ending with
          <strong>
            {{ phoneNum() }}
          </strong>
        </h3>
        <div class="mt-6 flex items-center justify-center flex-col gap-x-4">
          <h3 class="font-bold">I didn't receive the text</h3>
          <p class="text-sm font-normal">Please Note- The Link in that text is only valid for 5 minutes</p>
        </div>
      </div>
    </div>
  </div>
</div>
