<div
  class="flex h-20 bg-slate-100 w-full items-center justify-center rounded-lg gap-x-5 border-dashed border-2 border-slate-300">
  <div
    class="flex items-center justify-center text-white h-14 w-14 rounded-full bg-royalblue-100"
    [ngClass]="isDisabled ? 'bg-slate-300' : ''">
    <input
      class="hidden"
      [disabled]="loading() || isDisabled"
      type="file"
      (change)="handleFileInput($event)"
      #fileInput
      accept=".jpg,.jpeg,.png,.pdf,.HEIC,.WEBP" />
    <svg
      *ngIf="!loading()"
      role="button"
      (click)="fileInput.click()"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-cloud-upload">
      <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
      <path d="M12 12v9" />
      <path d="m16 16-4-4-4 4" />
    </svg>

    <svg
      *ngIf="loading()"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-loader-circle animate-spin duration-100 transition text-slate-300">
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  </div>
  <div class="flex flex-col text-center">
    <p class="text-base">Click to upload</p>
    <span class="text-xs uppercase leading-tight">(jpg,jpeg,png,pdf,HEIC,WEBP)</span>
  </div>
</div>
<div *ngIf="isVisibleList">
  <div *ngFor="let file of files" class="flex flex-col list-decimal mt-2.5">
    <app-file-list [file]="file" (deletedValue)="handleOnDeleted($event)"></app-file-list>
  </div>
</div>
