/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection!: signalR.HubConnection;
  private notificationReceived = new Subject<any>();

  startConnection = (consumerId: string) => {
    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(`${environment.baseAPI}/notify-kyc-status`).build();

    this.hubConnection
      .start()
      .then(() => {
        console.log('SignalR Connection started');
        this.registerNotificationEvent();
        console.log('register successfully');
        this.SubscribeUserToNotification(consumerId);
        // this.sendNotification()
      })
      .catch((err) => console.error('Error while starting SignalR connection: ' + err));
  };

  SubscribeUserToNotification = (consumerId: string) => {
    this.hubConnection
      .invoke('SubscribeUserToNotification', {
        consumerId: consumerId,
        fingerprint: null,
      })
      .then(() => {
        console.log(`Subscribed user ${consumerId} and notification sent.`);
      })
      .catch((error) => {
        console.error(`Error subscribing and notifying user: ${error}`);
      });
  };

  private registerNotificationEvent = () => {
    this.hubConnection.on('SendNotification', (data: any) => {
      console.log('Notification received:', data);
      this.notificationReceived.next(data);
    });
  };

  getNotificationListener = (): Observable<any> => {
    return this.notificationReceived.asObservable();
  };
}
