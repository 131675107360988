import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { formatSidebar, sidebarData } from '@shared/components/side-bar/sidebar';
import { IAlmostDone } from '@shared/model/IAlmostDone';
import { IResponseType } from '@shared/model/IResponse';
import { SidebarResponse } from '@shared/model/ISidebar';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlmostDoneService {
  private almostDoneValues = new BehaviorSubject<SidebarResponse[]>([]);
  private almostDoneValuesRaw = new BehaviorSubject<IAlmostDone[]>([]);
  private toggleSubject = new BehaviorSubject<boolean>(false);

  toggleSubjectData$: Observable<boolean> = this.toggleSubject.asObservable();
  currentAlmostDoneData$: Observable<SidebarResponse[]> = this.almostDoneValues.asObservable();
  currentAlmostData$: Observable<IAlmostDone[]> = this.almostDoneValuesRaw.asObservable();

  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Retrieves almost done statistics from the server and formats the data to be displayed in the sidebar.
   * @returns A Subscription object that can be used to unsubscribe from the HTTP request.
   */
  getAlmostDone(): Subscription {
    const url = `${environment.api}/Merchant/get-almost-done-statistic`;

    return this.httpClient.get<IResponseType<IAlmostDone[]>>(url).subscribe((response) => {
      let formattedData;
      if (response.data.length) {
        this.almostDoneValuesRaw.next(response.data);
        formattedData = formatSidebar(response.data);
      } else {
        formattedData = formatSidebar(sidebarData);
      }
      this.almostDoneValues.next(formattedData);
    });
  }

  toggleSidebar(): void {
    const currentValue = this.toggleSubject.value;
    this.toggleSubject.next(!currentValue);
  }
}
