<button
  class="bg-slate-800 text-white py-2 px-4 rounded text-base disabled:cursor-not-allowed disabled:opacity-70 font-semibold"
  [disabled]="isLoading">
  <ng-container *ngIf="isLoading; else saveButton">
    <div class="flex space-x-2 items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-loader-circle animate-spin h-4 w-4">
        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
      </svg>
      <span>Saving...</span>
    </div>
  </ng-container>
  <ng-template #saveButton>Save &#38; Quit</ng-template>
</button>
