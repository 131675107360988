import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from '@shared/constants';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({ providedIn: 'root' })
export class ErrorHandlerInterceptor implements HttpInterceptor {
  /**
   * Instantiate the interceptor
   *
   * @param messageService to handle alert
   */
  constructor(private messageService: MessageService) {}

  /**
   * Intercept the request and throw error if there is
   *
   * @param request to intercept
   * @param next event
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has('X-Skip-Error-Interceptor')) {
      const headers = request.headers.delete('X-Skip-Error-Interceptor');
      return next.handle(request.clone({ headers }));
    }
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
  }

  /**
   * Show error into log or anything
   *
   * @param response to parse the error
   */
  private errorHandler(response: HttpEvent<unknown>): Observable<HttpEvent<unknown>> {
    if (!(response instanceof HttpErrorResponse)) {
      throw response;
    }

    const e = response;
    let detail: string = constants.errorMessage;

    if (e.error) {
      if (e.status === 406) {
        const key = Object.keys(e?.error?.data)[0];
        detail = e?.error?.data[key];
      } else {
        detail = e.error?.message || e.error?.title;
      }
    } else if (e.message) {
      detail = e.message;
    }

    // TODO need to change
    if (e?.error?.message !== 'Client not found' && !e?.message.includes('get-company-information')) {
      this.messageService.add({
        severity: 'error',
        detail,
      });
    }

    throw response;
  }
}

export const ErrorHandlerInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorHandlerInterceptor,
  multi: true,
};
