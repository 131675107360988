import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerifyService } from '@shared/services/verify.service';

@Component({
  selector: 'app-verify-owner',
  templateUrl: './verify-owner.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class VerifyOwnerComponent implements OnInit {
  phoneNum = signal<number>(0);
  constructor(
    private route: ActivatedRoute,
    private readonly verifyOwnerService: VerifyService
  ) {}

  /**
   * Initializes the component after it has been initialized.
   * Retrieves query parameters from the URL and calls a service method.
   */
  ngOnInit(): void {
    const filter = this.route.snapshot.queryParamMap.get('id');
    const phone = this.route.snapshot.queryParamMap.get('phone');

    if (phone && filter) {
      this.phoneNum.set(+phone);
      this.verifyOwnerService.verifyCompanyOwnerKYC(filter).subscribe();
    }
  }
}
