import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';

import { IoniaButtonComponent } from '../ionia-button/ionia-button.component';
import { SaveQuiteComponent } from '../save-quite/save-quite.component';

@Component({
  selector: 'app-page-layout',
  standalone: true,
  imports: [
    IoniaButtonComponent,
    RouterModule,
    ButtonModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    SaveQuiteComponent,
    NgIf,
  ],
  templateUrl: './page-layout.component.html',
})
export class PageLayoutComponent {
  @Input() backButtonURL = '';
  @Input() isVisible = true;
  @Input() isBackButtonVisible = true;
  @Input() variant = '';
  @Output() buttonClicked = new EventEmitter<void>();

  constructor(private router: Router) {}

  handleOnBackButtonClick(): void {
    this.router.navigateByUrl(this.backButtonURL);
  }

  handleClick(): void {
    this.buttonClicked.emit();
  }
}
