<div class="xl:bg-gray-100 relative">
  <div class="xl:bg-white">
    <header class="w-full mx-auto bg-royalblue-100 py-4">
      <div class="px-3 md:mx-20 flex flex-col md:flex-row gap-y-3">
        <img src="assets/logo/logo_idp.png" alt="logo" class="w-32 md:w-[85px]" />
        <div class="flex-1 items-end justify-end flex">
          <div class="flex gap-x-6 items-center">
            <ul class="gap-x-5 text-base font-medium md:flex text-white uppercase">
              <li><a (click)="isContactVisible = true" class="cursor-pointer">Contact Us</a></li>
            </ul>
            <div class="gap-3 md:ml-4 md:flex flex pr-4">
              <a
                routerLink="/auth/sign-in"
                class="px-4 py-2 cursor-pointer bg-transparent bg-white border text-slate-800 rounded-full text-sm">
                Login
              </a>
              <button (click)="handleOnApply()" class="px-4 py-2 text-white border rounded-full text-sm">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- first section -->
    <section
      class="flex flex-row items-start justify-between pt-12 md:pt-0 md:px-20 box-border relative max-w-full text-left text-4xl md:text-[60px] text-primary-black font-body-large-regular">
      <div
        class="h-[662px] flex-1 relative bg-primary-white overflow-hidden max-w-full flex justify-between items-center flex-col md:flex-row">
        <div
          class="md:w-[630px] flex flex-col items-start justify-start py-[3px] px-0 box-border gap-8 max-w-full z-[1] relative">
          <h1
            class="m-0 md:w-[576px] relative text-inherit leading-[48px] sm:leading-[66px] font-medium font-inherit inline-block max-w-full">
            Introducing IoniaPay’s Instant Direct Payments
            <p class="text-xl font-bold absolute bottom-6 sm:bottom-10 right-40 sm:right-24">TM</p>
          </h1>
          <div class="relative text-xl leading-[150%]">
            Instant Direct Payments™ (IDP) is a revolutionary instant payments rail that directs your customers’ funds
            to your bank account with as few steps as possible, following instant settlement. Plus, you are fully
            indemnified against any fraud chargeback risks.
          </div>
          <button
            (click)="openVideo()"
            class="cursor-pointer [border:none] py-3 px-8 bg-[transparent] rounded-4xl [background:linear-gradient(138.85deg,_#6381f5_17%,_#32447f_84.45%)] flex flex-row items-start justify-start whitespace-nowrap rounded-full text-white">
            <div
              class="relative text-base leading-[125%] uppercase font-semibold font-body-large-regular text-primary-white text-center inline-block min-w-[122px] cursor-pointer">
              Watch Video
            </div>
          </button>
        </div>
        <div>
          <img
            class="absolute top-[60%] md:top-[50%] md:h-[1906px] w-full object-cover left-[20%]"
            alt=""
            src="./assets/icons/union.svg" />
          <img
            class="md:w-[610px] h-[472px] md:object-cover z-[1] relative"
            loading="lazy"
            alt=""
            src="./assets/img/image@2x.png" />
        </div>
      </div>
    </section>

    <!-- second section -->
    <section
      class="flex flex-row items-start justify-center py-10 md:pt-16 px-10 md:px-20 box-border text-center text-xl md:text-[48px] text-primary-black mx-auto font-body-large-regular relative">
      <img
        class="h-6 md:h-[44.7px] md:w-[44.7px] absolute z-[1] left-[1rem] 2xl:left-[13rem] md:left-[6.5rem] top-8 md:top-[50px]"
        alt=""
        src="/assets/icons/subtract.svg" />
      <h1
        class="m-0 h-[77px] md:w-[1397px] relative text-start md:text-center leading-[110%] inline-block shrink-0 max-w-full font-inherit space-x-2">
        <span class="font-medium">Instant Settlements, with</span>
        <i class="font-medium">Zero</i>
        <i class="font-medium">Interchange</i>
        <span class="font-medium">Fees</span>
      </h1>
    </section>

    <!-- third section -->
    <section
      class="bg-[#F9F9F9] overflow-hidden flex flex-col md:flex-row items-end justify-center py-2 sm:py-0 pr-0 box-border md:gap-[122px] max-w-full shrink-0 md:text-left text-primary-black font-body-large-regular">
      <div
        class="flex flex-col items-start justify-start pb-4 md:pb-[50px] box-border md:min-h-[494px] max-w-full mx-20">
        <div class="flex flex-col items-start justify-start gap-[37px] max-w-full">
          <div class="flex flex-row items-start justify-start py-0 box-border">
            <h1
              class="flex-1 md:relative text-inherit leading-[110%] font-medium font-inherit inline-block max-w-full text-xl md:text-3xl">
              The first consumer-to-merchant instant payments rail
            </h1>
          </div>
          <div class="relative text-base md:text-xl leading-[140%] mq450:text-base">
            Instant Direct Payments™ reduces the steps involved in traditional payment flows, creating a direct
            connection between consumer and merchant. The streamlined process results in a frictionless payment
            experience for your customers.
          </div>
        </div>
      </div>
      <div
        class="h-[635px] flex-1 md:flex items-center justify-center overflow-hidden min-w-[634px] max-w-ful hidden relative">
        <div class="absolute left-8 top-32 2xl:left-6">
          <img class="object-contain" alt="" src="/assets/img/dashboard.png" />
        </div>
      </div>
    </section>

    <!-- fourth section -->

    <section
      class="flex flex-row items-start justify-start pt-0 pb-4 md:pb-[30px] box-border max-w-full shrink-0 text-left text-xl md:text-3xl text-grayscale-black">
      <div
        class="flex-1 bg-lavender overflow-hidden flex flex-col items-start justify-start pt-[30px] md:pt-[88px] pb-[48px] md:pb-[98px] pl-4 md:pl-60 md:pr-[239px] box-border gap-[11px] max-w-full px-2">
        <h2
          class="m-0 relative text-inherit uppercase font-normal font-inherit flex items-center max-w-full shrink-0 text-base md:text-xl">
          Getting started with IoniaPay
        </h2>
        <div
          class="w-[877px] flex flex-row items-start justify-start pt-0 px-0 pb-6 md:pb-[39.5px] box-border max-w-full shrink-0 text-xl md:text-3xl">
          <h1 class="m-0 flex-1 relative text-inherit font-medium font-inherit inline-block max-w-full">
            Frequently asked questions
          </h1>
        </div>
        <div
          class="h-2 md:h-[34.5px] flex flex-row items-start justify-start pt-0 px-0 pb-3 md:pb-[33.5px] box-border w-full shrink-0">
          <div class="flex-1 relative border-gray-400 border-t-[1px] border-solid box-border max-w-full"></div>
        </div>
        <div class="flex flex-row items-start justify-start pt-0 px-4 md:px-[55px] box-border max-w-full shrink-0">
          <h2 class="m-0 relative text-lg md:text-2xl font-semibold">IoniaPay Merchant Application Process</h2>
        </div>
        <div
          class="flex flex-row items-start justify-center pt-0 pr-0 box-border max-w-full shrink-0 text-xl pl-4 md:pl-36">
          <div class="relative inline-block shrink-0 max-w-full text-[14px] md:text-xl">
            <p class="md:text-2xl leading-[160%] font-semibold font-body-large-regular">
              What documents will I need to apply?
            </p>
            <span class="font-medium font-body-large-regular text-lg md:text-xl mt-1">Owner Documents:</span>
            <ol class="list-disc pl-10 space-y-2">
              <li>
                <span>ID document (passport, driver’s license, state-issued ID)</span>
              </li>
            </ol>
            <span class="font-medium font-body-large-regular text-lg md:text-xl">Company Documents:</span>
            <ul class="m-0 pl-10 space-y-2 list-disc">
              <li>
                <span>DBA name documents (if applicable)</span>
              </li>
              <li>
                <span>EIN documents</span>
              </li>
              <li>
                <span>Completed/signed Certification of Beneficial Owners Form</span>
              </li>
              <li>
                <span>3 months prior processing/history statements</span>
              </li>
              <li>
                <span>Voided check</span>
              </li>
            </ul>

            <p class="text-xl md:text-2xl leading-[160%] font-semibold font-body-large-regular pt-6">
              How do I apply to become an IoniaPay merchant?
            </p>

            <ol class="m-0 pl-[27px] list-disc pt-4">
              <li>
                <span class="font-semibold">Start the Application</span>
                : Click on the Apply button in the upper right corner of this page.
              </li>
              <li>
                <span class="font-semibold">Provide Required Information</span>
                : The application will ask for the following:
              </li>
            </ol>
            <ul class="m-0 pl-20 list-disc">
              <li>
                <span class="text-grayscale-black">
                  <span class="leading-[180%]">
                    <span>
                      <span class="font-body-large-regular">
                        Business details (name, address, tax ID, industry type)
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li>
                <span class="text-grayscale-black">
                  <span class="leading-[180%]">
                    <span>
                      <span class="font-body-large-regular">
                        Contact information for the primary business owner or authorized representative
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li>
                <span class="text-grayscale-black">
                  <span class="leading-[180%]">
                    <span>
                      <span class="font-body-large-regular">
                        Details about your processing needs (estimated transaction volume, types of payments accepted)
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li>
                <span class="text-grayscale-black">
                  <span class="leading-[180%]">
                    <span>Bank account information for receiving funds</span>
                  </span>
                </span>
              </li>
            </ul>
            <ol class="m-0 pl-[27px] list-disc">
              <li>
                <span class="text-grayscale-black">
                  <span class="leading-[180%]">
                    <span>
                      <span class="font-semibold">Supporting Documentation</span>
                      : You may be asked to upload:
                    </span>
                  </span>
                </span>
              </li>
            </ol>
            <ul class="m-0 pl-20 list-disc">
              <li>
                <span class="text-grayscale-black">
                  <span class="leading-[180%]">Government-issued ID for the business owner</span>
                </span>
              </li>
              <li>
                <span class="text-grayscale-black">
                  <span class="leading-[180%]">Business license or registration documents</span>
                </span>
              </li>
              <li>
                <span class="text-grayscale-black">
                  <span class="leading-[180%]">Bank statements</span>
                </span>
              </li>
            </ul>
            <ol class="m-0 pl-[27px] list-disc">
              <li>
                <span class="text-grayscale-black">
                  <span class="leading-[180%]">
                    <span class="font-semibold">Submit Your Application</span>
                    : Once you've completed the form and provided necessary documents, carefully review your information
                    before submitting application.      
                  </span>
                </span>
              </li>
            </ol>
            <p>
              <span class="text-grayscale-black">
                <span>
                  <span class="font-body-large-regular">
                    <span class="tracking-[-0.03em] whitespace-pre-wrap">
                      <span class="leading-[160%]"> </span>
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p>
              <span class="text-xl md:text-2xl font-semibold font-body-large-regular">
                What happens after I submit my application?
              </span>
            </p>
            <p class="pt-3">
              <span class="font-medium font-body-large-regular">Review</span>
              <span class="font-body-large-regular">
                : The Ionia team will thoroughly review your application and supporting materials.
              </span>
            </p>
            <p>
              <span class="text-grayscale-black">
                <span class="leading-[180%]">
                  <span class="text-[14px] md:text-xl">
                    <span class="font-medium font-body-large-regular">Decision</span>
                    <span class="font-body-large-regular">
                      : You'll usually receive a decision within 24-48 business hours. Ionia may contact you for
                      additional information if needed.
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p>
              <span class="text-grayscale-black">
                <span class="leading-[180%]">
                  <span class="text-[14px] md:text-xl">
                    <span class="font-medium font-body-large-regular">Approval and Onboarding</span>
                    <span class="font-body-large-regular whitespace-pre-wrap">
                      : If approved, you will receive more information guiding you through the onboarding process and
                      setting up your merchant account.
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p>
              <span class="text-grayscale-black">
                <span class="tracking-[-0.03em]">
                  <span class="font-body-large-regular whitespace-pre-wrap">
                    <span class="leading-[160%]">&nbsp;</span>
                  </span>
                </span>
              </span>
            </p>
            <p>
              <span class="text-xl md:text-2xl font-semibold font-body-large-regular">
                How will I know the status of my application?
              </span>
            </p>
            <p class="pt-3">
              <span class="text-[14px] md:text-xl">
                We will send you several emails during the application process:
              </span>
            </p>
            <ul class="m-0 pl-[27px] list-disc">
              <li>
                <span class="text-grayscale-black">
                  <span class="tracking-[-0.03em]">
                    <span class="leading-[180%]">
                      <span class="text-[14px] md:text-xl">
                        <span class="font-medium font-body-large-regular">
                          Merchant Application Submission Confirmation
                        </span>
                        <span class="font-body-large-regular">
                          : We will send you an email confirming your application has been submitted.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li>
                <span class="text-grayscale-black">
                  <span class="tracking-[-0.03em]">
                    <span class="leading-[180%]">
                      <span class="text-[14px] md:text-xl">
                        <span class="font-medium font-body-large-regular">
                          Request for further information or documents:
                        </span>
                        <span class="font-body-large-regular whitespace-pre-wrap">
                          If we need more information during the review process, we will send you an email with
                          information needs and instructions on uploading documentation to a secure location.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li>
                <span class="text-grayscale-black">
                  <span class="tracking-[-0.03em]">
                    <span class="leading-[180%]">
                      <span class="text-[14px] md:text-xl">
                        <span class="font-medium font-body-large-regular">Merchant Application Under Review</span>
                        <span class="font-body-large-regular">
                          : Once your application has been reviewed, we’ll send you an email with the determination. If
                          approved, we will send you a welcome email with instructions on how to set up your account. If
                          declined, we will send information regarding the decision.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <p>
              <span class="text-grayscale-black">
                <span class="tracking-[-0.03em]">
                  <span class="text-xl">
                    <span class="leading-[160%]">
                      <span class="font-body-large-regular">&nbsp;</span>
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p>
              <span class="text-xl md:text-2xl font-semibold font-body-large-regular">
                How long does the application process take?
              </span>
            </p>
            <p class="pt-3">
              <span class="font-body-large-regular">
                The process can often be completed within a few business days. However, if extra verification is
                required, it may take a little longer.
              </span>
            </p>

            <p class="pt-10">
              <span class="text-grayscale-black">
                <span class="font-semibold font-body-large-regular">Further Questions</span>
                <span class="font-body-large-regular">
                  ? Please don't hesitate to contact IoniaPay Support by emailing us at
                </span>
              </span>

              <span class="font-medium font-body-large-regular text-royalblue-300 text-royalblue-100">
                support&#64;ionia.io
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- fifth section -->

    <section
      class="bg-primary-white overflow-hidden md:flex flex-col items-center justify-start pt-4 md:pt-[30.9px] mx-auto box-border gap-[59.1px] max-w-7xl shrink-0 text-center text-xl md:text-4xl text-primary-black font-body-large-regular px-4">
      <h1 class="w-full relative text-inherit tracking-[-0.03em] leading-[110%] inline-block font-inherit">
        A suite of features designed with your business in mind
      </h1>

      <div
        class="flex flex-row flex-wrap items-start justify-center gap-[30px] max-w-full text-left text-13xl shrink-1 md:h-[400px] pt-4">
        <div
          class="flex-1 rounded-t-[20px] rounded-br-none rounded-bl-[20px] [background:linear-gradient(116.64deg,_#fcf0e7,_#fceee4)] overflow-hidden flex flex-col items-start justify-start p-10 box-border gap-6 min-w-[345px] max-w-full h-full">
          <img class="h-8 w-8 sm:w-[55px] sm:h-[55px] relative" alt="" src="/assets/icons/group-1000002594-1.svg" />

          <div class="relative font-medium inline-block shrink-0 max-w-full mq450:text-base">
            <p class="mb-2">
              <span class="tracking-[-0.03em] leading-[110%] text-xl md:text-3xl">
                <span>Customer Initiates Payment</span>
              </span>
            </p>
            <span class="text-[20px]">Say goodbye to waiting. Your funds, available in minutes instead of days.</span>
          </div>
        </div>
        <div
          class="flex-1 rounded-t-[20px] rounded-br-none rounded-bl-[20px] [background:linear-gradient(116.64deg,_#fcf0e7,_#fceee4)] overflow-hidden flex flex-col items-start justify-start p-10 box-border gap-6 min-w-[345px] max-w-full h-full">
          <img class="h-8 w-8 sm:w-[55px] sm:h-[55px] relative" alt="" src="/assets/icons/group-1000002592-1.svg" />

          <div class="w-[361.2px] relative font-medium inline-block shrink-0 max-w-full mq450:text-base">
            <p class="">
              <span class="tracking-[-0.03em] leading-[110%] text-xl md:text-3xl">
                <span>Pay Less</span>
              </span>
            </p>
            <p class="mb-2 text-xl md:text-3xl">
              <span class="tracking-[-0.03em] leading-[110%]">
                <span>in Fees</span>
              </span>
            </p>

            <p class="m-0">
              <span>
                <span class="text-[20px]">Less steps in the settlement process means more money goes to you.</span>
              </span>
            </p>
          </div>
        </div>
        <div
          class="flex-1 rounded-t-[20px] rounded-br-none rounded-bl-[20px] [background:linear-gradient(116.64deg,_#fcf0e7,_#fceee4)] overflow-hidden flex flex-col items-start justify-start p-10 box-border gap-6 min-w-[345px] max-w-full h-full">
          <img class="h-8 w-8 sm:w-[55px] sm:h-[55px] relative" alt="" src="/assets/icons/group-1000002591-1.svg" />

          <div class="w-[361.2px] relative font-medium inline-block shrink-0 max-w-full mq450:text-base">
            <p class="mb-2">
              <span class="tracking-[-0.03em] leading-[110%] text-xl md:text-3xl">
                <span>Complete Fraud Indemnification</span>
              </span>
            </p>
            <p class="m-0">
              <span>
                <span class="text-[20px]">Get completely indemnified from fraud chargeback risk.</span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="md:h-[543px] bg-primary-white overflow-hidden shrink-0 flex flex-col items-end justify-start pt-[100px] md:pt-[159px] md:pb-[139px] pl-0 pr-3.5 box-border gap-[0.8px] max-w-4xl mx-auto text-left text-2xl md:text-5xl text-primary-black font-body-large-regular px-4">
      <div class="md:w-[1869px] flex flex-row items-center justify-center py-0 px-5 box-border max-w-full shrink-0">
        <div class="md:w-[795px] flex flex-col items-center justify-center gap-[67px] shrink-0 max-w-full">
          <div class="flex flex-col items-start justify-start gap-[38px] max-w-full">
            <div class="flex flex-row items-start justify-start relative max-w-full">
              <h1
                class="pl-2 flex-1 relative text-inherit tracking-[-0.03em] leading-[110%] font-medium font-inherit inline-block max-w-full">
                Open your account today
              </h1>
              <img
                class="h-[44.9px] w-[44.9px] absolute !m-[0] top-[-22.3px] left-[-12px] md:left-[-37px] z-[1]"
                alt=""
                src="/assets/icons/subtract-1.svg" />
            </div>
            <div class="flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-xl">
              <div
                class="w-[531px] relative leading-[125%] inline-block shrink-0 max-w-full mq450:text-base mq450:leading-[20px]">
                Enroll in minutes, enjoy a lifetime of financial agility.
              </div>
            </div>
          </div>
          <div class="md:w-[758.6px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
            <button
              (click)="handleToApply()"
              class="cursor-pointer [border:none] pt-[11.1px] pb-[13.2px] pl-[13px] pr-3 bg-[transparent] w-[162.6px] rounded-3xl-5 [background:linear-gradient(138.85deg,_#6381f5_17%,_#32447f_84.45%)] flex flex-row items-start justify-start box-border whitespace-nowrap rounded-full">
              <div
                class="h-[45px] w-[162.6px] relative rounded-3xl-5 [background:linear-gradient(138.85deg,_#6381f5_17%,_#32447f_84.45%)] hidden"></div>
              <div class="flex-1 relative text-mini uppercase font-semibold text-white text-center z-[1] text-sm">
                apply now
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="md:h-[1906px] h-10 relative max-w-full overflow-hidden shrink-0"></div>
    </section>

    <!-- footer section -->
    <footer
      class="md:ml-[-4px] [background:linear-gradient(142.02deg,_#32447f,_#000)] overflow-hidden flex flex-col items-start justify-start pt-[40px] px-4 md:px-[100px] pb-[82px] box-border gap-10 max-w-full shrink-0 text-left text-base text-grayscale-off-white font-body-large-regular">
      <img class="w-[45px] h-[45px] relative hidden" alt="" src="/assets/icons/group1.svg" />

      <div class="md:w-[5px] h-[5px] relative object-cover hidden"></div>

      <div class="w-[481px] flex flex-col items-start md:items-start justify-start gap-10 max-w-full text-white">
        <div class="flex flex-row items-start justify-start py-0 px-px">
          <img class="h-[47px] w-[265px] relative" loading="lazy" alt="" src="/assets/icons/group-1000002516.svg" />
        </div>
        <div class="flex flex-col md:flex-row items-start justify-between gap-10 mq450:flex-wrap">
          <div
            (click)="isContactVisible = true"
            class="flex flex-col items-center md:items-start justify-start pt-[10.9px] px-0 pb-0 cursor-pointer">
            <div class="relative leading-[145%] font-medium inline-block min-w-[90px] text-center">Contact Us</div>
          </div>
          <div class="flex flex-col md:flex-row items-start justify-start gap-4">
            <button
              (click)="handleToApply()"
              class="cursor-pointer [border:none] pt-[11.1px] pb-[13.2px] pl-[13px] pr-3 bg-[transparent] w-[162.6px] rounded-3xl-5 [background:linear-gradient(138.85deg,_#6381f5_17%,_#32447f_84.45%)] flex flex-row items-start justify-start box-border whitespace-nowrap rounded-full order-2">
              <div
                class="h-[45px] w-[162.6px] relative rounded-3xl-5 [background:linear-gradient(138.85deg,_#6381f5_17%,_#32447f_84.45%)] hidden"></div>
              <div class="flex-1 relative text-mini uppercase font-semibold text-white text-center z-[1] text-sm">
                apply now
              </div>
            </button>

            <button
              routerLink="/auth/sign-in"
              class="cursor-pointer [border:none] md:pt-[11px] md:px-[17px] md:pb-[13px] bg-primary-white md:w-[132px] rounded-4xl overflow-hidden shrink-0 flex flex-row items-start justify-start box-border hover:bg-gainsboro-200 border-white">
              <div class="flex-1 relative uppercase font-semibold text-primary-black text-center">login</div>
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-start justify-start gap-[19px] max-w-full text-[12px] w-full text-white">
        <div class="h-px w-full relative border-new-gray border-t-[2px] border-solid box-border"></div>
        <div class="w-[704.3px] relative tracking-[0.02em] font-medium inline-block max-w-full">
          <p class="m-0">
            <span class="leading-[140%]">© Ionia 2024. All Rights Reserved.</span>
          </p>
          <p class="m-0">
            <span class="leading-[140%]">
              Covered by U.S. Patent No. 10,395,234 and one or more other pending applications.
            </span>
          </p>
          <p class="m-0">
            <span class="leading-[140%]">
              Banking, Payments, and Card services are provided by Ionia's partner banks that are members of the FDIC.
            </span>
          </p>
          <p class="m-0">
            <span class="leading-[125%]">&nbsp;</span>
          </p>
          <p class="m-0">
            <a class="text-[inherit]" href="https://fileshare.ioniapay.com/dl/AOxDSXjiVE" target="_blank">
              <span class="leading-[125%]">
                <span class="[text-decoration:underline]">Terms of Service</span>
              </span>
            </a>
            <span class="leading-[125%]">|</span>
            <a
              class="text-[inherit] ml-1"
              href="https://fileshare.ioniapay.com/dl/2D3C2nHwb4/Ionia_Pay_Privacy_Policy.pdf_"
              target="_blank">
              <span class="leading-[125%]">
                <span class="[text-decoration:underline]">Privacy Policy</span>
              </span>
            </a>
          </p>
        </div>
      </div>
    </footer>

    <p-dialog
      [(visible)]="isContactVisible"
      [style]="{ width: modalWidth }"
      [modal]="true"
      position="center"
      [header]="modalTitle">
      <div *ngIf="isVideo; else other_content">
        <div class="video-container">
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/985205720?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
              title="OpenPath x IoniaPay Sales Demo"></iframe>
          </div>
        </div>
      </div>

      <ng-template #other_content>
        <form [formGroup]="contactForm" (ngSubmit)="submit()" class="profile-form">
          <div class="flex items-start justify-start flex-col">
            <div class="mb-4 w-full">
              <label class="block text-gray-700 text-sm mb-2" for="email">Name</label>
              <input
                class="input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                formControlName="name" />
              <div
                *ngIf="f['name'].invalid && (f['name'].dirty || f['name'].touched)"
                class="text-red-500 text-xs font-normal italic mt-1">
                <div *ngIf="f['name'].errors?.['required']">Name is required</div>
              </div>
            </div>
            <div class="mb-4 w-full">
              <label class="block text-gray-700 text-sm mb-2" for="email">Email</label>
              <input
                class="input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                formControlName="email" />
              <div
                *ngIf="f['email'].invalid && (f['email'].dirty || f['email'].touched)"
                class="text-red-500 text-xs font-normal italic mt-1">
                <div *ngIf="f['email'].errors?.['required']">Email is required</div>
                <div *ngIf="f['email'].errors?.['email']">Email must be a valid email address</div>
              </div>
            </div>
            <div class="mb-4 w-full">
              <label class="block text-gray-700 text-sm mb-2" for="email">Message</label>
              <textarea
                class="input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message"
                type="text"
                formControlName="message"
                rows="4"></textarea>
              <div
                *ngIf="f['message'].invalid && (f['message'].dirty || f['message'].touched)"
                class="text-red-500 text-xs font-normal italic mt-1">
                <div *ngIf="f['message'].errors?.['required']">Message is required</div>
              </div>
            </div>
            <div class="flex justify-between w-full">
              <a
                (click)="cancel()"
                class="bg-white py-2 mr-2 flex items-center justify-center rounded-full gap-x-2 text-base font-semibold border border-[#DBDBDB] cursor-pointer w-[130px] h-[52px]">
                Cancel
              </a>
              <div
                class="bg-slate-800 pl-6 hover:bg-slate-900 transition-colors duration-100 text-white flex items-center rounded-full gap-x-2 w-[140px]">
                <a (click)="submit()" class="text-base font-medium py-2 cursor-pointer flex items-center">
                  Submit
                  <img src="assets/landing_images/layer_button.svg" alt="layer_button" class="h-10 w-10 pl-2" />
                </a>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </p-dialog>
  </div>
</div>
