import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { FileFormatType } from '@shared/model/ICompanyDocument';
import { FileUploadService } from '@shared/services/file-upload.service';
import { MessageService } from 'primeng/api';

import { FileListComponent } from '../file-list/file-list.component';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [CommonModule, FileListComponent],
  templateUrl: './file-upload.component.html',
  providers: [FileUploadService],
})
export class FileUploadComponent {
  @Input() isVisibleList = true;
  @Input() isMultipleUpload = false;
  @Input() isDisabled = false;
  @Input() tokenOutSide = '';
  @Output() filesUploaded = new EventEmitter<FileFormatType[]>();
  files: FileFormatType[] = [];
  loading = signal<boolean>(false);
  maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

  constructor(
    private readonly fileUploadService: FileUploadService,
    private messageService: MessageService
  ) {}

  handleFileInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files: FileList = target.files!;
    const filesData: { name: string; id: string }[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > this.maxFileSize) {
        this.messageService.add({
          severity: 'error',
          detail: 'File size exceeds the 10MB limit.',
        });
        continue;
      }
      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result as string;
        const base64Data = base64String.split(',')[1];
        this.loading.set(true);
        await this.fileUploadService
          .uploadFile(
            {
              base64data: base64Data,
              fileName: file.name,
            },
            this.tokenOutSide
          )
          .then((res) => {
            if (res) {
              filesData.push({ name: file.name, id: res });
              this.files.push({ name: file.name, id: res });
              this.filesUploaded.emit(filesData);
            }
          })
          .finally(() => {
            this.loading.set(false);
            if (event.target) {
              target.value = ''; // Reset the file input to allow re-upload of the same file
            }
          });
      };
      reader.readAsDataURL(file);
    }
  }

  handleOnDeleted(id: string): void {
    this.files = this.files.filter((item) => item.id !== id);
    this.filesUploaded.emit(this.files);
  }
}
