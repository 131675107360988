import { importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';

import { ToastModule } from 'primeng/toast';
import { SharedModule } from '@shared/shared.module';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { CoreModule } from './app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutes } from './app/app.routes';
import { RouterModule } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      RouterModule,
      FormsModule,
      CoreModule,
      ReactiveFormsModule,
      SharedModule,
      ToastModule
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(RouterModule.forRoot(AppRoutes)),
  ],
}).catch((err) => console.error(err));
