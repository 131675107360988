import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IFileUpload, IFileUploadResponse } from '@shared/model/IFileUpload';
import { IResponseType } from '@shared/model/IResponse';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private readonly httpClient: HttpClient) {}

  uploadFile(upload: IFileUpload, token?: string): Promise<string> {
    const url = `${environment.api}/File/upload-base64`;
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<IResponseType<IFileUploadResponse>>(url, upload, {
          headers: token ? { Authorization: `Bearer ${token}`, 'content-type': 'application/json' } : {},
        })
        .subscribe(
          (response) => {
            resolve(response.data.fileUniqueId);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
