<div class="flex items-center justify-center">
  <div class="w-full sm:w-[40rem] md:w-[38rem] bg-white rounded-md mb-10 mx-4 relative">
    <ng-content></ng-content>
    <hr class="border border-[#DBDBDB] mt-6" />
    <div class="flex items-center justify-center">
      <ng-content select="[extraInfo]"></ng-content>
    </div>

    <div class="py-5 px-5">
      <div class="flex justify-between">
        <container-element [ngSwitch]="variant">
          <ng-content *ngSwitchCase="'checkbox'" select="[checkbox]"></ng-content>
          <div *ngSwitchDefault>
            <button
              *ngIf="isBackButtonVisible"
              (click)="handleOnBackButtonClick()"
              type="button"
              class="text-font-color py-2 px-4 rounded border text-base disabled:cursor-not-allowed disabled:opacity-70 font-semibold">
              Back
            </button>
          </div>
        </container-element>
        <div class="flex justify-end">
          <ng-content select="[saveButton]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex h-16 items-center justify-end border-t-2 absolute 2xl:-bottom-[270px] w-full">
  <div class="mx-6" *ngIf="isVisible">
    <app-save-quite (click)="handleClick()"></app-save-quite>
  </div>
</div>
