import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ionia-button',
  templateUrl: './ionia-button.component.html',
  standalone: true,
  imports: [NgClass, NgIf],
})
export class IoniaButtonComponent {
  @Input() label = '';
  @Input() bgColor? = '';
  @Input() loading = false;
  @Input() disabled? = false;
  @Input() icon = false;
  @Input() buttonType = 'submit';
}
