import { MessageService } from 'primeng/api';

type SeverityType = 'error' | 'success' | 'warning';

export class FormValidationsMsg {
  constructor(public messageService: MessageService) {}

  showToastMessage(message: string, severity: SeverityType = 'error'): void {
    this.messageService.add({
      severity,
      summary: message,
      closable: true,
    });
  }
}
