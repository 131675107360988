import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IMessage, IResponseType } from '@shared/model/IResponse';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VerifyService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Verifies the KYC (Know Your Customer) information of a company owner.
   * Sends a GET request to the specified API endpoint and returns the response.
   * @returns An observable that emits the response received from the API call.
   */
  public verifyCompanyOwnerKYC(id: string): Observable<IResponseType<{ message: string }>> {
    const url = `${environment.api}/Merchant/verify-company-owner-kyc?id=${id}`;
    return this.httpClient.post<IResponseType<{ message: string }>>(url, {});
  }

  /**
   * Verifies the referral code.
   * @param referralCode
   * @returns An observable that emits the response received from the API call.
   */
  verifyReferralCode(referralCode: string): Observable<IResponseType<{ message: string }>> {
    const url = `${environment.api}/Authentication/verify-referral-code`;
    const params = { referralCode: referralCode };
    return this.httpClient.get<IResponseType<{ message: string }>>(url, { params });
  }
}
