<div class="grid h-screen place-content-center bg-white px-4">
  <div class="text-center">
    <h1 class="text-9xl font-black text-gray-200">401</h1>
    <p class="text-2xl font-bold tracking-tight text-gray-200 sm:text-4xl">Uh-oh!</p>
    <div class="max-w-md text-center text-lg">
      <p class="mt-4 text-red-500">
        Looks like you don't have permission to access this page. If you believe you should have access, please contact
        your administrator
      </p>
    </div>
  </div>
</div>
