import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './file-list.component.html',
})
export class FileListComponent {
  @Input() file: { name: string; id: string } | undefined;

  @Output() deletedValue = new EventEmitter<string>();

  handleOnDelete(id?: string) {
    if (id) this.deletedValue.emit(id);
  }
}
