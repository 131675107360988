<app-header></app-header>
<div class="bg-pattern"></div>

<div class="absolute top-32 left-0 right-0">
  <app-page-layout backButtonURL="/home" [isVisible]="false" [isBackButtonVisible]="false">
    <form [formGroup]="underWritingForm" #formGroupRef="ngForm">
      <div class="pt-10 rounded-lg w-full min-h-max flex flex-col justify-center items-center bg-white">
        <div class="rounded-lg max-w-md w-full">
          <h2 class="text-lg font-semibold text-gray-700 mb-6">Additional Underwriting Documents</h2>

          <!-- Upload Section -->
          <div class="mb-4">
            <label class="block text-gray-600 text-sm mb-2" for="file-upload">Upload of Required Documents</label>
            <p class="text-xs text-gray-500 mb-4">
              Please utilize the click-to-upload feature to securely upload any additional documents.
            </p>

            <app-file-upload
              [isDisabled]="getUnderwritingFiles.length === maxUploadDocs"
              [isMultipleUpload]="true"
              [tokenOutSide]="token"
              (filesUploaded)="handleFileUpload($event)"></app-file-upload>
          </div>
          <!-- Feedback Section -->
          <div class="mb-6">
            <label class="block text-gray-600 text-sm mb-2" for="feedback">Under writing feedback</label>
            <div class="flex">
              <textarea
                formControlName="feedback"
                id="feedback"
                rows="4"
                class="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:border-blue-500 text-sm"
                placeholder="Your feedback..."></textarea>
            </div>
            <div
              *ngIf="f['feedback'].invalid && (f['feedback'].dirty || f['feedback'].touched)"
              class="text-red-500 text-xs font-normal italic mt-1">
              <app-error-message
                [control]="f['feedback']"
                maxLength="250"
                fieldName="Feedback"
                *ngIf="f['feedback'].errors"
                [errors]="f['feedback'].errors"></app-error-message>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div saveButton class="flex justify-center items-center gap-x-2 sm:gap-x-4">
      <app-ionia-button
        [loading]="isLoading()"
        [disabled]="isValidDendButton()"
        [icon]="true"
        [label]="'Send'"
        [buttonType]="'button'"
        (click)="onSubmit()"></app-ionia-button>
    </div>
  </app-page-layout>
</div>
