<div class="flex items-center justify-center w-full">
  <div class="sm:w-[40rem] md:w-[38rem] bg-card-color rounded-md mb-10 mx-2 lg:mx-4 relative">
    <ng-content></ng-content>
    <hr class="border border-[#DBDBDB] mt-6" />
    <div class="flex items-center justify-center">
      <ng-content select="[extraInfo]"></ng-content>
    </div>

    <div class="py-5 px-5">
      <div class="flex justify-between">
        <container-element [ngSwitch]="variant">
          <ng-content *ngSwitchCase="'checkbox'" select="[checkbox]"></ng-content>
          <div *ngSwitchDefault>
            <button
              *ngIf="isBackButtonVisible"
              (click)="handleOnBackButtonClick()"
              type="button"
              class="border px-4 rounded-lg py-2 flex items-center justify-center gap-2">
              <i class="pi pi-arrow-left" style="font-size: 1rem"></i>
              Back
            </button>
          </div>
        </container-element>
        <div class="flex justify-end">
          <ng-content select="[saveButton]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex h-16 border-t-2">
  <div class="mx-6 pt-3 absolute right-2 sm:right-12" *ngIf="isVisible">
    <app-save-quite (click)="handleClick()"></app-save-quite>
  </div>
</div>
