import { SidebarInterface, SidebarResponse } from '@shared/model/ISidebar';

export const sidebarData = [
  {
    totalCount: 0,
    filledCount: 0,
    formName: 'Company Information',
  },
  {
    totalCount: 0,
    filledCount: 0,
    formName: 'Follow Up Questions',
  },
  {
    totalCount: 0,
    filledCount: 0,
    formName: 'Company Contacts',
  },
  {
    totalCount: 0,
    filledCount: 0,
    formName: 'External Banking',
  },
  {
    totalCount: 0,
    filledCount: 0,
    formName: 'Owner Profiles',
  },
  {
    totalCount: 0,
    filledCount: 0,
    formName: 'Company Documents',
  },
];

function formateString(name: string): string {
  return `/merchants/application/${name.toLowerCase().split(' ').join('-')}`;
}

/**
 * Formats the sidebar data by mapping each SidebarInterface object to a SidebarResponse object.
 * @param data - An array of SidebarInterface objects representing the sidebar data.
 * @returns An array of SidebarResponse objects representing the formatted sidebar data.
 */

export const formatSidebar = (data: SidebarInterface[]): SidebarResponse[] => {
  let isStarted = false;
  const companyInformationfilledCount = data.find((x) => x.formName === 'Company Information')?.filledCount;

  if (companyInformationfilledCount && companyInformationfilledCount > 0) {
    isStarted = true;
  }

  return data.map((item) => {
    const { formName } = item;
    const href = formateString(formName);
    const isCompleted = item.isAllRequiredFilled;

    return {
      name: formName,
      href,
      isCompleted,
      isStarted,
    };
  });
};
