import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MessageService } from 'primeng/api';

import { ErrorHandlerInterceptorProvider } from './interceptors/error-handler.interceptor';
import { TokenInterceptorProvider } from './interceptors/token.interceptor';

@NgModule({
  imports: [CommonModule, RouterModule],
  providers: [MessageService, TokenInterceptorProvider, ErrorHandlerInterceptorProvider],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    // Import guard

    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
