/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignalRService } from '@shared/services/signalr.service';

@Component({
  selector: 'app-signalrtest',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './signalrtest.component.html',
})
export class SignalrtestComponent {
  constructor(
    private signalRService: SignalRService,
    private route: ActivatedRoute
  ) {}

  sendNotification() {
    this.route.queryParams.subscribe((params) => {
      if (params['id'] != null && params['id'] != '') {
        this.signalRService.startConnection(params['id']);
      }
    });

    this.signalRService.getNotificationListener().subscribe((data: any) => {
      console.log('Received notification:', data);
    });
  }
}
