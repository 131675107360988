import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';

import { HeaderComponent } from './components/header/header.component';
import { IoniaButtonComponent } from './components/ionia-button/ionia-button.component';
import { AuthenticationService } from './services/authentication.service';

@NgModule({
  exports: [HeaderComponent, IoniaButtonComponent],
  imports: [CommonModule, ButtonModule, RouterModule, HeaderComponent, IoniaButtonComponent],
  providers: [AuthenticationService, MessageService],
})
export class SharedModule {}
