import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IUnderWriting } from '@shared/model/IUnderWriting';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnderWritingService {
  constructor(private readonly httpClient: HttpClient) {}

  createUnderWriting(payload: IUnderWriting, token: string): Observable<IUnderWriting> {
    const url = `${environment.api}/Merchant/upload-additional-documents`;
    if (!token) {
      return new Observable<IUnderWriting>(); // Return an empty observable
    }
    return this.httpClient.post<IUnderWriting>(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
    });
  }
}
