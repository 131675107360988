<div class="grid h-screen place-content-center bg-white px-4">
  <div class="text-center">
    <h1 class="text-9xl font-black text-gray-200">403</h1>
    <p class="text-2xl font-bold tracking-tight text-gray-200 sm:text-4xl">Uh-oh!</p>
    <div class="max-w-md text-center text-lg">
      <p>The URL for this client is no longer valid</p>
      <a
        href="/home"
        class="mt-6 inline-block rounded bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring">
        Go Back Home
      </a>
    </div>
  </div>
</div>
